import axios from "axios";

const apiURL = process.env.VUE_APP_URL;


const publicApi = {
    // Fetch menu data
    getMenu() {
      console.log('You are on ' + process.env.VUE_APP_URL)

      return axios.get(apiURL + '/api/menus')
        .then(response => response.data)
        .catch(error => {
          console.error('Error fetching menu data:', error);
          throw error;
        });
    },

    getPost(id) {
        return axios.get(apiURL + '/api/posts/'+ id)
        .then(response => response.data)
        .catch(error => {
          console.error('Error fetching menu data:', error);
          throw error;
        });
    },

    getCars(sold, length = 999) {
        const params = {
            'filters[sold][$eq]': sold, 
            'pagination[page]': 1,      
            'pagination[pageSize]': length,
            'populate':'*'
        };
    
        return axios.get(apiURL + '/api/cars', { params })
            .then(response => response.data)
            .catch(error => {
                console.error('Error fetching car data:', error);
                throw error;
            });
        },

        getCar(id) {
          const params = {
            'populate':'*'
          };
      
          return axios.get(apiURL + '/api/cars/' + id, { params })
              .then(response => response.data)
              .catch(error => {
                  console.error('Error fetching car data:', error);
                  throw error;
              });
          },
  
    // Fetch another public resource
    getPublicResource(resourceName) {
      return axios.get(`/${resourceName}`)
        .then(response => response.data)
        .catch(error => {
          console.error(`Error fetching ${resourceName}:`, error);
          throw error;
        });
    },
  
  };
  
  export default publicApi;