import "primeicons/primeicons.css";
import './styles.scss'

import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

import axios from './services/publicCalls.ts';

import Divider from 'primevue/divider';
import Button from "primevue/button"
import Menubar from 'primevue/menubar';
import Card from 'primevue/card';
import Avatar from 'primevue/avatar';
import Galleria from 'primevue/galleria';


import App from './App.vue'

import { createWebHistory, createRouter } from 'vue-router'
// import HelloWorld from "./components/HelloWorld.vue";
// import HelloWorldNew from "./components/HelloWorldNew.vue";
import IndexCar from "./components/IndexCar.vue";
import CarList from "./components/CarList.vue";
import CarPost from "./components/CarPost.vue";
import CarDetail from "./components/CarDetail.vue";



const routes = [
  { path: '/', component: IndexCar },
  { path: '/vozidla', component: CarList, props: { msg: 'AKTUÁLNÍ NABÍDKA VOZIDEL', sold: false } },
  { path: '/prodana-vozidla', component: CarList, props: { msg: 'HISTORIE PRODANÝCH VOZIDEL', sold: true} },
  { path: '/dovoz', component: CarPost, props: { msg: 'Dovoz automobilů z Kanady a USA', id: 1} },
  { path: '/detail/:id', component: CarDetail, props: true },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


const app = createApp(App);

app.config.globalProperties.$axios = axios;

app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});

app.component('PAvatar', Avatar);
app.component('PDivider', Divider);
app.component('PButton', Button);
app.component('PMenubar', Menubar);
app.component('PCard', Card);
app.component('PGalleria', Galleria);

app.use(router)

app.mount("#app");
