<template>
  <div class="flex-parent">
    <!-- Brand and Model Container -->
    <div class="flex-container">
      <h1 v-if="carData.brand">{{ carData.brand }}</h1>
      <h1 v-if="carData.model">{{ carData.model }}</h1>
    </div>

    <!-- Year and Kilometers Container -->
    <div class="flex-container">
      <h3 v-if="carData.year">{{ carData.year }}</h3>
      <h3 v-if="carData.km">/{{ carData.km }}</h3>
    </div>

    <div v-if="pictures != []">
      <PGalleria :value="pictures" :numVisible="5" :circular="true" containerStyle="max-width: 640px">
        <template #item="slotProps">
          <img
            :src="slotProps.item.itemImageSrc"
            :alt="slotProps.item.alt"
            style="width: 100%"
          />
        </template>
        <template #thumbnail="slotProps">
          <img
            :src="slotProps.item.thumbnailImageSrc"
            :alt="slotProps.item.alt"
          />
        </template>
      </PGalleria>
    </div>
  </div>
</template>


<script>
import publicApi from '../services/publicCalls.ts'


export default {
  name: 'CarDetailCar',
  props: {
    id: String,
  },methods: {
    async fetchCar() {
      try {
        this.carItems = await publicApi.getCar(this.id);
        console.log(this.carItems)

        this.carData = this.carItems.data.attributes
        if(this.carData.picture.data) {
          this.pictures = []
          this.carData.picture.data.map(i => {
            this.pictures.push(
              {
                itemImageSrc: process.env.VUE_APP_URL + i.attributes.formats.large.url,
                thumbnailImageSrc: process.env.VUE_APP_URL + i.attributes.formats.thumbnail.url,
              }
                  // i
                // console.log(i)
                // console.log(i)
                // i.data.attributes,
              )
              // console.log(this.cars)
        });
        }
      } catch (error) {
        console.error('Failed to load menu:', error);
      }
    },
  },   beforeMount() {
      this.fetchCar()
    }, data() {
      return {
        carData: {},
        pictures: [],
        esponsiveOptions:[
    {
        breakpoint: '1300px',
        numVisible: 4
    },
    {
        breakpoint: '575px',
        numVisible: 1
    }
]
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-parent {
  display: flex;
  flex-direction: column; /* Stack flex containers vertically */
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px; /* Adjusts space between the two flex containers */
}

.flex-container {
  display: flex;
  flex-direction: row; /* Align items inside each container horizontally */
  align-items: center; /* Vertically center items in the container */
}


</style>
