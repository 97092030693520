<template>
   <div v-if="items != []" class="card">
        <PMenubar :model="items" >
          <template #start>
            <RouterLink to="/">
              <img alt="Vue logo" style="height: 60px;" src="./assets/logo-denker.webp">
            </RouterLink>
          </template>
          <template #item="{ item }">
                  <RouterLink :to="item.route">
                    <PButton :label="item.label" severity="secondary" />
                  </RouterLink>
          </template>
          <template #end >
           
            <i class="pi pi-instagram"></i>
            <i class="pi pi-facebook"></i>
            <i class="pi pi-vimeo"></i>
            
          </template>
        </PMenubar>
    </div>
    <main>
    <RouterView />
    <CarFooter></CarFooter> 
  </main>
</template>

<script>
import publicApi from './services/publicCalls.ts';
import { useRouter } from 'vue-router';
import CarFooter from './components/CarFooter.vue'


export default {
  name: 'App',
  components: {
    CarFooter
  }, methods: {
    async fetchMenu() {
      try {
        this.menuItems = await publicApi.getMenu();
        console.log(this.menuItems)
        this.menuItems.data.map(i => {
          this.items.push({
                    label: i.attributes.label,
                    icon: i.attributes.icon,
                    route: i.attributes.route
                },)
                console.log(this.items)
        });
      } catch (error) {
        console.error('Failed to load menu:', error);
      }
    },
    },
    beforeMount() {
      this.fetchMenu(),

      console.log('You are on ' + process.env.NODE_ENV)
      
    },
    data() {
        return {
            items: [],
            router: useRouter(),
        };
    }
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
