<template>
    
    <CarList msg="AKTUÁLNÍ NABÍDKA VOZŮ" sold='false' length="3" ></CarList> 
</template>

<script>

import CarList from './CarList.vue';

export default {
  name: 'IndexCar',
  props: {
    msg: String,
  },components: {
    CarList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
