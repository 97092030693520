<template>

<PCard style="width: 25rem; overflow: hidden">
  <template #header>
    <img
    v-if="imageURL && imageURL.length > 0 && imageURL[0] && imageURL[0].attributes"
    :alt="'user header'"
      :src="getFullImageUrl(imageURL[0].attributes.url)"
      style="width: 100% ;"
    />
    <img 
      v-else
      alt="user header"
      :src="giveAPIURL() + '/uploads/mceu_51757751611715611908205_jpg_c595e48eb2.webp'"
      style="width: 100% ;"
    />
  </template>
    <template #title>{{ brand }} {{ model }}</template>
    <template #subtitle>
      <div class="flex-container">
        <div class="flex-item">{{ power }} kw</div>
        <div class="flex-item">{{ price }},- CZK</div>
        <div class="flex-item">year: {{ year }}</div>
        <div class="flex-item">{{ km }} km</div>
      </div>
    </template>
    <template #footer>
        <div class="flex gap-4 mt-1">
            <RouterLink :to="'/detail/' + id">
              <PButton label="Detail" severity="secondary" />
            </RouterLink>
            <PButton label="Detail" severity="secondary" outlined class="w-full" />
        </div>
    </template>
</PCard>
</template>

<script>

export default {
  name: 'CarCard',
  props: {
    id: String,
    power: String,
    brand: String,
    model: String,
    year: String,
    price: String,
    km: String,
    imageURL: String,
  },
   methods: {
    getFullImageUrl(relativeUrl) {
      return process.env.VUE_APP_URL + relativeUrl;
    },
    giveAPIURL(){
      return process.env.VUE_APP_URL
    },
  },
   beforeMount() {
    }, data() {
      return {
      }
    }
}
</script>
