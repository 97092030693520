<template>
    <h1 class="header-text" >{{msg}}</h1>

    <PDivider />

  <div v-if="cars.length > 0" class="flex-container">
  <CarCard
    v-for="(car, index) in cars"
    :key="index"
    :brand="car.brand"
    :power="car.power"
    :model="car.model"
    :year="car.year"
    :price="car.price"
    :km="car.km"
    :id="car.id"
    :imageURL="car.picture.data"
  >
    {{ car.Brand }}
  </CarCard>
</div>
<PDivider />

</template>

<script>

import publicApi from '../services/publicCalls.ts'
import CarCard from './CarCard.vue'


export default {
  name: 'CarList',
  props: {
    msg: String,
    length: Number,
    sold: Boolean
  },
  components: {
    CarCard
  }, watch: {
    sold(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchCars();
      }
    },
    $route(to, from) {
      if (to.path !== from.path) {
        this.fetchCars();
      }
    }
  },
   methods: {
    async fetchCars() {
      try {
        this.carItems = await publicApi.getCars(this.sold, this.length);
        console.log(this.carItems)
        this.cars = []
        this.carItems.data.map(i => {
                    i.attributes.id = i.id
          this.cars.push(
                     i.attributes,
                    )
        });
      } catch (error) {
        console.error('Failed to load menu:', error);
      }
    },
 
  },
   beforeMount() {
      this.fetchCars()
    }, data() {
      return {
        cars: [],
      }
    }
}
</script>
<!-- ${process.env.VUE_API_URL}+ /uploads/1991_toyota_camry_sedan_le_v6_fq_oem_1_500_7d28196a39.avif -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  gap: 16px; /* Add space between items */
  justify-content: center; /* Center items horizontally */
}

.CarCard {
  flex: 1 1 calc(25% - 16px); /* 4 cards per row with space for gap */
  box-sizing: border-box; /* Include padding and border in width */
  max-width: calc(25% - 16px); /* Ensure cards don't exceed 25% of container width */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .CarCard {
    flex: 1 1 calc(33.333% - 16px); /* 3 cards per row */
    max-width: calc(33.333% - 16px);
  }
}

@media (max-width: 900px) {
  .CarCard {
    flex: 1 1 calc(50% - 16px); /* 2 cards per row */
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .CarCard {
    flex: 1 1 100%; /* 1 card per row */
    max-width: 100%;
  }
}

</style>
