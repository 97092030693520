<template >
    <div v-if="post  && post.data.attributes">
  <h1 class="header-text">{{ post.data.attributes.Header }}</h1>

  <PDivider />


  <div v-for="(section, index) in post.data.attributes.WholeBody" :key="index">
    <template v-if="section.type === 'paragraph'">
      <p class="white-text left-aligned" v-for="(child, childIndex) in section.children" :key="childIndex">
        <span v-if="child.bold"><strong>{{ child.text }}</strong></span>
        <span v-else>{{ child.text }}</span>
      </p>
    </template>
    <template v-else-if="section.type === 'heading'">
      <h2 class="white-text left-aligned">{{ section.children[0].text }}</h2>
    </template>
  </div>

</div>
<div v-else >
   Loading ...
</div>

</template>

<script>

import publicApi from '../services/publicCalls.ts'


export default {
name: 'CarPost',
props: {
  msg: String,
  id: Number,
},
components: {
},
 methods: {
  async fetchCars() {
    try {
      this.post = await publicApi.getPost(this.id);
      console.log(this.post)
    } catch (error) {
      console.error('Failed to load menu:', error);
    }
  },

},
 beforeMount() {
    this.fetchCars()
  }, data() {
    return {
      post: null,
    }
  }
}
</script>
<style scoped>
.white-text {
  color: white;
}

.left-aligned {
  text-align: left;
}
</style>
