<template>
  <footer class="footer">
    <div class="footer-section">
      <h2>MAJITEL</h2>
      <PAvatar   :image="returnURL('/uploads/fotomajitel_300x300_1_8937016bb6.webp')"
      class="mr-2 owner-avatar" size="xlarge" shape="circle" />
      <ul>
        <li>Jaromír Jirgala</li>
        <li>+420 775 722 300</li>
        <li>jirgala@denker.cz</li>
      </ul>
    </div>
    <div class="footer-section">
      <h2>DENKER s. r. o.</h2>
      <ul>
        <li>IČ: 03799034</li>
        <li>DIČ: CZ03799034</li>
        <li>Tel.: +420 775 722 300</li>
        <li>Email: info@denker.cz</li>
      </ul>
      <ul>
        <li><b><a href="#services">KONTAKTNÍ FORMULÁŘ</a></b></li>
        <li><b><a href="#services">NEWSLETTER</a></b></li>
      </ul>
    </div>
    <div class="footer-section">
      <h2>Sídlo:</h2>
      <ul>
        <li>Knínice 330</li>
        <li>67934 Knínice u Boskovic</li>
      </ul>
      <h2>Provozní doba:</h2>
      <ul>
        <li>K návštěvě se telefonicky nebo elektronicky objednejte na konkrétní datum a čas, děkujeme za pochopení. Na autoservisní zakázky využijte rezervační systém.</li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'CarFooter',
  data() {
    return {
      // You can add any data properties here if needed
    };
  },
  methods: {
   returnURL(arg) {
    return process.env.VUE_APP_URL+ arg 
   }
  },
  beforeMount() {
    // You can add any code here to execute before the component is mounted
  }
};
</script>

<style scoped>

.owner-avatar {
    width: 40%;
    height: 45%;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
  color: white;
}

.footer-section {
  flex: 1;
  margin: 0 15px;
}

.footer-section h2 {
  margin-bottom: 10px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section a {
  color: white;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}
</style>
